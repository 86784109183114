import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { makeStyles, Typography, Paper, Grid, Divider, Button } from '@material-ui/core'




const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        gap: '2%',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        }
    },
    gridItem: {
        width: 'auto',
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
            '-webkit-text-fill-color': 'white',
            
        },
        '&:hover #call2Action':{
            color: 'black',
            backgroundColor: 'white',
            '-webkit-text-fill-color': 'black',
        },
        
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },

    },
    customButton: {
        whiteSpace: 'nowrap',
        color: 'white',
        '-webkit-text-fill-color': 'white',
        backgroundColor: 'black',
        borderRadius: 0,
        marginTop: '5%'
        
    },
    paperContent: {
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingTop: '10%',
        paddingBottom: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        }

    },
    menuBoxHeader: {
        textDecoration: 'none',
        fontFamily: ['"Helvetica Neue"', 'sans-serif'],
        fontWeight: 600,
        fontSize: '2rem',
        lineHeight: '3rem',
        textAlign: 'center',      
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1.2',
        }
    },
    link: {
        textDecoration: 'none !important;',
        '&:visited': {
            color: theme.palette.text.primary,
            '-webkit-text-fill-color': theme.palette.text.primary
        },
        color: theme.palette.text.primary,
        '&:hover': {
            color: 'white',
            '-webkit-text-fill-color': 'white'
        },

    },
    menuBoxTextSection: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 'auto',
            paddingRight:'auto'
        }

    },
    iconBox: {
        padding: 0
    },
    callToAction: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        marginTop: '5%',
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1,
            marginTop: '5%'
        }
    },
    



}))
const MenuBox = (props) => {
    const [state, setState] = React.useState({ mobileView: true })
    const classes = useStyles()
    const { header, link, icon, callToAction, subtitle, type } = props

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    return (
        <Paper className={classes.paper} square elevation={0}>
            <Link to={link} className={classes.link}>
                <div className={classes.paperContent}>
                    {icon && <div className={classes.iconBox}>
                        {icon}
                    </div>}
                    <div className={classes.menuBoxTextSection}>
                        <Typography className={classes.menuBoxHeader}>{header}</Typography>
                        <Typography color='textSecondary'>{subtitle}</Typography>
                        {callToAction && <CalltoAction type={type} label={callToAction} />}
                    </div>
                </div>
            </Link>
            {state.mobileView &&

                <Divider />
            }
        </Paper>
    )
}

const CalltoAction = props => {
    const classes=useStyles()
    const { type, label } = props
    switch (type) {
        case 'button': return <Button id='call2Action' className={classes.customButton}>{label}</Button>
        default: return <Typography variant='subtitle1' className={classes.callToAction}>{label}</Typography>
    }
}

const MenuBoxContainer = (props) => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
            {props.menuItems.map((item, key) => (
                <Grid item key={key} className={classes.gridItem}>
                    <MenuBox
                        header={item.header}
                        link={item.link}
                        icon={item.icon}
                        callToAction={item.callToAction}
                        subtitle={item.subtitle}
                        type={item.buttonType || ''}

                    />
                </Grid>
            ))}

        </Grid>
    )
}

export default MenuBoxContainer