import React from 'react'

const getPath = () => (<g>
    <path d="M188.09,83.79c-2.28,0-4.5,0.27-6.64,0.81c-2.94-2.09-6.3-3.58-9.9-4.29c-2.07-5.68-5.24-10.97-9.42-15.6
        c-10.3-11.41-26.12-18.22-42.33-18.22c-12.46,0-24.03,3.91-33.44,11.32c-6.91,5.44-12.4,12.6-16.26,21.11
        c-6.77,0.52-13.28,3.78-18.09,9.13c-1.02,1.13-1.93,2.32-2.75,3.57c-6.06,2.59-10.9,8-13.02,15.03c-0.8,2.65-1.15,5.32-1.07,7.93
        h15.78H201.6h15.41h0.45c0.31-4.46-0.47-8.98-2.38-13.25C210.37,90.84,199.52,83.79,188.09,83.79z" fill="currentColor" />
    <path d="M246.22,201.3l-14.65-63.56c-1.11-4.8-5.4-8.21-10.32-8.21H34.76c-4.92,0-9.22,3.41-10.32,8.21L9.78,201.3
        c-0.97,4.21,2.22,8.21,6.54,8.21h223.36C244,209.51,247.19,205.51,246.22,201.3z M183.4,182.98c-0.01,8.85-7.19,16.03-16.04,16.02
        c-8.85-0.01-16.03-7.19-16.02-16.04c0-0.2,0.02-0.4,0.03-0.61l-27.56-0.03l-17.11-0.01c0.01,0.2,0.03,0.4,0.03,0.6
        c-0.01,8.85-7.19,16.03-16.04,16.02c-8.85-0.01-16.03-7.19-16.02-16.04c0-4.58,1.94-8.71,5.02-11.63
        c-3.08-2.92-5.01-7.05-5.01-11.63c0-8.85,7.19-16.03,16.04-16.02c8.85,0,16.03,7.19,16.02,16.04c0,0.2-0.02,0.4-0.03,0.61
        l17.11,0.02l27.56,0.02c-0.01-0.2-0.03-0.4-0.03-0.6c0.01-8.85,7.19-16.03,16.04-16.02c8.86,0.01,16.03,7.19,16.02,16.04
        c0,4.58-1.94,8.71-5.02,11.63C181.47,174.27,183.4,178.39,183.4,182.98z" fill="currentColor" />
</g>)

const DogFood = (props) =>
    <svg
        width={props.width}
        length={props.height}
        style={{ maxHeight: '100%', ...props.style }}
        viewBox={props.viewBox}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        {getPath()}

    </svg>;

export default DogFood