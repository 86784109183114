import React from 'react'

const getPath = () => (<g>
  <path d="M51.46,207.78c0.07,0.62,0.15,1.24,0.26,1.86c2.66,14.91,16.91,24.83,31.82,22.17
        c14.91-2.67,24.83-16.91,22.16-31.82c-1.19-6.66-4.7-12.32-9.54-16.32l91.01-78.47c6.38,7.35,16.35,11.26,26.63,9.42
        c15.54-2.78,25.88-17.63,23.1-33.16c-2.03-11.35-10.5-19.92-20.97-22.64c0.27-2.84,0.18-5.76-0.35-8.71
        c-3.11-17.39-19.73-28.96-37.11-25.86c-17.39,3.11-28.97,19.73-25.86,37.12c0.87,4.89,2.82,9.32,5.54,13.09l-87.99,75.87
        c-6.9-5.29-15.91-7.77-25.13-6.12c-17.41,3.11-29.01,19.76-25.89,37.17C21.97,197.18,35.91,208.18,51.46,207.78z" fill="currentColor" />
</g>)

const Bone = (props) =>
    <svg
        width={props.width}
        length={props.height}
        style={{ maxHeight: '100%', ...props.style }}
        viewBox={props.viewBox}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        {getPath()}

    </svg>;

export default Bone