import React from 'react'

const getPath = () => (<g>
    <path d="M135.09,139.57c10.08,0.03,18.29,8.14,18.47,18.18l29.94,0.09l6.38-9.16c-10.59-14.29-27.36-21.5-44.77-22.99
c-0.95-1.29-1.8-2.71-2.55-4.06c0.32-0.28,0.68-0.52,1.06-0.68c0.83-0.34,1.69-0.53,2.6-0.73c1.78-0.39,3.8-0.83,5.72-2.54
c0.23-0.2,0.41-0.43,0.61-0.65c0.09,0.05,0.19,0.11,0.28,0.14c0.86,0.3,1.99,0.39,2.9,0.29c0.68-0.07,1.38-0.24,2.02-0.47
c2.14-0.77,3.84-2.32,5.04-4.2c0.21-0.33,0.4-0.67,0.58-1.02c1.36-2.65,1.58-5.91,1.36-8.88c-0.05-0.7-0.86-1.11-1.4-0.65
c-1.65,1.38-3.5,2.42-5.6,3.01c-1.3,0.37-2.65,0.5-3.93,0.91c-1.55,0.5-3.08,1.39-3.86,2.88c-0.68,1.29-0.68,3.04-0.27,4.42
c0.05,0.16,0.13,0.31,0.19,0.47c-0.22,0.24-0.42,0.49-0.65,0.69c-1.11,0.99-2.33,1.26-3.87,1.59c-1,0.22-2.12,0.47-3.29,0.95
c-0.39,0.16-0.75,0.38-1.11,0.61c-2.24-7.8,1.54-15.58,5.48-21.05c0.8-1.11,1.7-2.19,2.65-3.33c1.14-1.37,2.33-2.79,3.42-4.38
c0.2-0.3,0.41-0.61,0.61-0.92c0.36-0.14,0.72-0.27,1.07-0.46c8.48-4.5,20.91-13.69,19.82-28.41c-1.33-18.01-14.57-25.5-19.33-34.08
c-0.99-1.79-3.59-2.16-4.49-0.32c-2.65,5.41-6.91,13.63-11.65,20.81c-8.29,12.58-13.78,22.22,0.56,38.07
c2.48-2.06,4.79-4.42,6.94-6.98c2.15-2.59,4.17-5.34,5.92-8.29c-0.88,3.32-2.1,6.59-3.52,9.79c-1.42,3.15-3.09,6.23-5.1,9.17
c0,0,0.01,0.01,0.01,0.01c-0.03,0.03-0.05,0.07-0.08,0.1c-0.01,0.01-0.01,0.02-0.02,0.04c-1.12,1.61-2.35,3.18-3.71,4.69
c-3.04,4.29-5.17,8.6-6.36,12.84c-1.61-1.12-3.47-1.91-5.29-2.68c-0.75-0.32-1.43-0.62-2.07-0.92c-0.29-0.13-0.58-0.27-0.84-0.4
c-0.66-0.34-1.23-0.69-1.74-1.07c0.83-2.16,0.54-5.21-1.37-8.77c-2.97-5.51-11.69-8.49-11.69-8.49s-2.15,8.84,0.82,14.35
c2.77,5.14,6.4,6.95,9.32,5.84c1.8,1.47,4,2.41,5.98,3.25c2.41,1.01,4.65,2,5.95,3.61c-0.64,5.52,0.35,10.82,3.03,15.66
c-15.81,0.55-31.24,5.58-42.19,14.02L135.09,139.57z" fill="currentColor" />
    <path d="M242.28,120.85l-10.86-1.2c-0.5-0.06-0.99-0.08-1.48-0.08c-4.52-0.01-8.83,2.2-11.44,5.95l-22.89,32.89l-3.77,5.42
l-0.62,0.9c-0.44,0.63-0.97,1.18-1.56,1.64c-1.38,1.11-3.12,1.74-4.93,1.73l-17.97-0.05L149.76,168c-0.3,0.58-0.62,1.15-0.96,1.71
c-4.38,7.17-12.27,11.98-21.27,11.95l-35.3-0.1l-5.59-0.02c-2.77-0.01-5.01-2.26-5-5.03c0.01-2.77,2.26-5.01,5.03-5l0.71,0
l40.18,0.12c4.51,0.01,8.56-2.01,11.29-5.2c1.34-1.56,2.35-3.4,2.95-5.42c0.39-1.32,0.61-2.71,0.61-4.15c0-0.6,0.13-1.17,0.32-1.7
c-1.18-3.1-4.16-5.31-7.67-5.32l-51.79-0.15l-6.66-0.02c-5.39-0.02-10.69,1.51-15.24,4.39l-22.06,13.97
c-4.55,2.88-8.16,7.06-10.36,11.98c-5.09,11.41-14.64,32.94-19.49,44.5c-1.56,3.72,1.25,7.77,5.28,7.78l38.38,0.11
c4.98,0.02,9.48-3.03,11.33-7.64l7.27-18.13c0.93-2.31,3.18-3.83,5.67-3.82l115.09,0.33c5.11,0.01,9.9-2.66,12.57-7.03l41.12-67.3
C248.19,125.53,246.11,121.28,242.28,120.85z" fill="currentColor" />
</g>)


const SustainabilityIcon = (props) =>
    <svg
        width={props.width}
        length={props.height}
        style={{ maxHeight: '100%', ...props.style }}
        viewBox={props.viewBox}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        {getPath()}

    </svg>;

export default SustainabilityIcon