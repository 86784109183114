import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FWBanner from '../../../components/fullWidthBanner';
import MenuBoxContainer from '../../../components/menuBoxes';
import Bone from '../../../components/custom-icons/bone'
import DogFood from '../../../components/custom-icons/dog-food';
import Sustainability from '../../../components/custom-icons/sustainability'


const menuBoxProps = [{
    header: 'Our Values',
    link: '/en/about-us/our-values',
    icon: <Bone name='bone' width='100px' height='auto' viewBox='0 0 256 256' />,
    callToAction: 'Read More'
},
{
    header: 'ProParent',
    link: '/en/about-us/pro-parent',
    icon: <DogFood name='dog-food' width='100px' height='auto' viewBox='0 0 256 256' />,
    callToAction: 'For a happy and healthy life'
},
{
    header: 'Sustainability',
    link: '/en/about-us/sustainability',
    icon: <Sustainability name='sustainability' width='100px' height='auto' viewBox='0 0 256 256' />,
    callToAction: "Let's Make The World A Better Place"
}
]


const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),

    },
    promoHeader: theme.typography.promoHeader,
    paragraph: {
        marginBottom: theme.spacing(5),
    },
    mt5: {
        marginTop: '50px',
    }

}))

export default function WhoWeAreEn(props) {
    const classes = useStyles()
    const locale = 'en-EU'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='Who We Are' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWBanner imgName='about-us-desktop.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <WhoWeAreHeader />
                    <WhoWeAreBodyTr />


                </Grid>
                <Grid item className={classes.mt5} xs={12}>
                    <MenuBoxContainer menuItems={menuBoxProps} />
                </Grid>

            </Grid>
        </Layout>
    )

}

const WhoWeAreHeader = props => {
    const classes = useStyles()
    return (
        <Box mt={5}>
            <Typography component='h1' className={classes.promoHeader}>
                Who we are?
            </Typography>
        </Box>
    )

}

const WhoWeAreBodyTr = props => {
    const classes = useStyles()
    return (
        <Box >
            <Typography component='p' display='block' className={classes.paragraph}>
                Three friends came together with the idea to prepare food for their beloved pets. Their
                aim was to make sure their pets did not eat anything they themselves wouldn’t! So with
                the help of expert veterinarians and animal nutritionists they founded “Prochoice”. Today,
                we are a big family with more than 150 employees, 26 dogs and 30 cats.
            </Typography>
            <Typography component='p' className={classes.paragraph}>
                Our passion and #1 priority is to make sure that our furry friends live a happy and healthy
                life. We believe that our pets, to whom we feel very close in every sense, are not
                different from us in terms of their affection for food. Your cats and dogs will feel like
                gourmets while eating our appetizing foods prepared using natural and fresh ingredients!
            </Typography>
            <Typography component='p' display='block' className={classes.paragraph}>
                We work very closely with marvelous vets and nutritionists to make delicious, quality
                recipes using wholesome ingredients. All our pet foods are produced according to a
                special recipe with carefully selected and prepared ingredients designed by experts, and

                then tested for flavor, palatability, and nutrients.
            </Typography>

        </Box>
    )
}







